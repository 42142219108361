<template>
  <el-card>
    <el-row
      class="filter-wrap"
      :gutter="10"
    >
      <el-col :span="4">
        <div class="flex-start">
          <el-input
            v-model="listQuery.searchStr"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
        </div>
      </el-col>
      <el-col :span="3">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </el-col>
      <el-col
        :span="17"
        class="text-right"
      >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="toSave(1)"
        >
          新增
        </el-button>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="标题"
        prop="title"
      />
      <el-table-column
        prop="introduction"
        :show-overflow-tooltip="true"
        label="简介"
      />
      <el-table-column
        prop="firstImageUrl"
        label="首图"
      >
        <template slot-scope="{row}">
          <el-image
            style="width: 80px;height: 80px"
            :src="row.firstImageUrl"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="source"
        :show-overflow-tooltip="true"
        label="来源"
      />
      <el-table-column
        prop="sourceUrl"
        :show-overflow-tooltip="true"
        label="来源地址"
      />
      <el-table-column
        prop="releaseDate"
        label="发布时间"
      />
      <el-table-column
        label="操作"
        width="120px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="toSave(2,row.id)"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            class="mr10"
            type="primary"
            @click="preview(row.id)"
          >
            <el-tooltip
              content="预览"
              placement="top"
              effect="light"
            >
              <i class="el-icon-document" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="del(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="listQuery.page"
      :page-size="listQuery.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="pageSizeChangeHandle"
      @current-change="pageCurrentChangeHandle"
    />
  </el-card>
</template>

<script>
import { error, success } from '@core/utils/utils'
import { listWebsite, deleteNews } from '@/api/website/website'

export default {
  components: {
  },

  data() {
    return {
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    preview(id) {
      const routeUrl = this.$router.resolve({
        path: '/news',
        query: {
          id,
          t: 1,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
    toSave(type, id) {
      if (type === 1) {
        this.$router.push({
          path: '/lawnewsSave',
          query: {
            t: 1,
          },
        })
      } else {
        this.$router.push({
          path: '/lawnewsSave',
          query: {
            id,
            t: 1,
          },
        })
      }
    },
    del(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteNews(1, { id })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          })
      })
    },
    getList() {
      this.loading = true
      listWebsite(1, this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    pageSizeChangeHandle(val) {
      this.listQuery.page = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    pageCurrentChangeHandle(val) {
      this.listQuery.page = val
      this.getList()
    },
  },
}
</script>
